import {useNavigate} from 'react-router';
import ssoCodeCheck from '../../api/ssoCodeCheck';
import {Navigate, useLocation, useSearchParams} from "react-router-dom";
import { Auth } from "aws-amplify";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import {useAuthenticator} from "@aws-amplify/ui-react";
import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {Box} from "@mui/material";


const handleAuthResponse = (resp, navigate) => {
    // create a CognitoAccessToken using the response accessToken
    const AccessToken = new
    AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: resp.access_token,
    });

    // create a CognitoIdToken using the response idToken
    const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: resp.id_token,
    });

    // create a RefreshToken using the response refreshToken
    const RefreshToken = new
    AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: resp.refresh_token,
    });

    // create a session object with all the tokens
    const sessionData = {
        IdToken: IdToken,
        AccessToken: AccessToken,
        RefreshToken: RefreshToken,
    };

    // create the CognitoUserSession using the sessionData
    const session = new AmazonCognitoIdentity.CognitoUserSession(
        sessionData
    );

    // create an object with the UserPoolId and ClientId
    var poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID,
    };

    // pass the poolData object to CognitoUserPool
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(
        poolData
    );

    // create an object containing the username and user pool.
    // You can get the username from CognitoAccessToken object
    // we created above.
    var userData = {
        Username: AccessToken.payload.username,
        Pool: userPool,
    };

    // create a cognito user using the userData object
    var cognitoUser = new AmazonCognitoIdentity.CognitoUser(
        userData
    );

    // set the cognito user session w/ the CognitoUserSession
    cognitoUser.setSignInUserSession(session);
};

function SSOReturn() {
    const [searchParams] = useSearchParams();
    const [user, setUser] = useState(null);
    const code = searchParams.get("code");
    const navigate = useNavigate();

    const { route } = useAuthenticator((context) => {
        return [context.route]
    });

    if(route === 'setup') {
        setTimeout(function(){
            window.location.reload();
        }, 1000);
    } else if (route === 'authenticated') {
        navigate('/costTool');
    }

    const startLogin = () => {
        let hostURI;
        window.location.hostname === 'localhost' ? hostURI = encodeURIComponent(`http://localhost:3000`) : hostURI = encodeURIComponent(`https://${window.location.hostname}`);

        const loginUrl = `https://costengine-sso-${process.env.REACT_APP_ENV_NAME}.auth.${process.env.REACT_APP_REGION}.amazoncognito.com/oauth2/authorize?client_id=${process.env.REACT_APP_USER_POOL_APP_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=${hostURI}%2Fsso%2Fokta`

        window.open(loginUrl, "_self");
    }

    useEffect(()=>{
        console.log(" UnR: ", user, route);
        let localUser = null;
        Auth.currentAuthenticatedUser()
            .then((u) => {
                localUser = u;
                setUser(localUser);
            })
            .catch((err) => console.log(err));

        if(!localUser && route !== 'authenticated'){
            ssoCodeCheck.post(code).then(
                (data) => {
                    handleAuthResponse(data, navigate);
                },
            ).catch(e => {
                console.log("error with post ",e)
                startLogin();
            });
        }
    },[])

    return (
        <Box sx={{position: 'absolute', left: '50%'}}>
            <CircularProgress/>
        </Box>
    );
}

export default SSOReturn;